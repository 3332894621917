import React, { PropsWithChildren, Suspense } from "react";
import { Loader } from "../Loader";
import { Card } from "primereact/card";
import styled from "styled-components";
import { useFragment, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { AuthScreenBase_UserInformationFragment$key } from "../../../../../__generated__/AuthScreenBase_UserInformationFragment.graphql";
import { NonAuthScreenBase } from "./NonAuthScreenBase";
import { BrandedContainer } from "../brand/BrandedContainer";
import { BrandIcon } from "../brand/BrandIcon";
import { useTypedDispatch } from "../../../../../Store";
import { logout } from "../../../../redux/slices/AuthSlice";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { AuthScreenBase_LogoutMutation } from "../../../../../__generated__/AuthScreenBase_LogoutMutation.graphql";
import { AuthScreenBase_BrandFragment$key } from "../../../../../__generated__/AuthScreenBase_BrandFragment.graphql";
import { PrimaryButton } from "../buttons/PrimaryButton";
import { TertiaryButton } from "../buttons/TertiaryButton";
import { toast } from "react-toastify";
import { RoleApplicationRegistrationForm } from "../../../roles/roleunlock/RoleApplicationRegistrationForm";

const USER_INFORMATION_FRAGMENT = graphql`
	fragment AuthScreenBase_UserInformationFragment on UserInformation {
		id
		roleApplicationProcess {
			targetRole
		}
		signedUpOverBrand {
			brandColorHex
			name
			brandUrl
			icon {
				url
			}
			...NonAuthScreenBase_BrandFragment
		}
		...RoleApplicationRegistrationForm_UserInformationFragment
	}
`;

const LOGOUT_MUTATION = graphql`
	mutation AuthScreenBase_LogoutMutation($input: LogoutInput!) {
		InfectopharmAuth {
			logout(input: $input) {
				clientMutationId
			}
		}
	}
`;
const BRAND_FRAGMENT = graphql`
	fragment AuthScreenBase_BrandFragment on Brand {
		id
		brandColorHex
		brandUrl
		icon {
			url
			name
		}
	}
`;
interface OwnProps {
	userFragmentRef: AuthScreenBase_UserInformationFragment$key;
	brandPlatformFragmentRef: AuthScreenBase_BrandFragment$key;
}

export const AuthScreenBase = ({
	userFragmentRef,
	brandPlatformFragmentRef,
	children,
}: PropsWithChildren<OwnProps>) => {
	const [ssoLogout] = useMutation<AuthScreenBase_LogoutMutation>(LOGOUT_MUTATION);
	const navigate = useNavigate();
	const userInformation = useFragment<AuthScreenBase_UserInformationFragment$key>(
		USER_INFORMATION_FRAGMENT,
		userFragmentRef,
	);
	const dispatch = useTypedDispatch();

	const match = useMatch("/login/:brandId");
	const brandIdParam = match?.params.brandId ?? process.env.REACT_APP_WISSENWIRKT_ID;
	const [params] = useSearchParams();
	const origin = params.get("origin");

	const brandPlatform = useFragment<AuthScreenBase_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandPlatformFragmentRef,
	);

	const getNavigationUrl = () => {
		if (origin) {
			return atob(origin);
		} else {
			if (match) {
				return (
					(brandIdParam === process.env.REACT_APP_PAEDIA_ID &&
						"https://www.paedia.de/") ||
					"https://www.infectopharm.com"
				);
			} else {
				return (
					userInformation.signedUpOverBrand?.brandUrl || "https://www.infectopharm.com"
				);
			}
		}
	};

	const getButtonLabel = () => {
		if (origin) {
			if (
				origin == process.env.REACT_APP_REDIRECT_BASE_64_WISSENWIRKT_APP_BASE ||
				origin == process.env.REACT_APP_REDIRECT_BASE_64_PAEDIA_APP_BASE
			) {
				return "Zurück zur Lernplattform";
			} else {
				return "Zurück zur Webseite";
			}
		} else {
			return "Zurück zur Webseite";
		}
	};

	if (!userInformation.roleApplicationProcess) {
		return (
			<NonAuthScreenBase brandFragmentRef={userInformation.signedUpOverBrand}>
				<RoleApplicationRegistrationForm userInformationFragment={userInformation} />
			</NonAuthScreenBase>
		);
	}

	return (
		<BrandedContainer
			brandColor={brandPlatform.brandColorHex}
			className="flex justify-content-center align-items-center flex-grow-1 pt-5 pb-5 surface-50"
		>
			<ContentContainer
				header={
					<div className="flex p-2">
						{brandPlatform.icon?.url && (
							<a href={getNavigationUrl()}>
								<BrandIcon
									alt={brandPlatform.icon?.name}
									src={brandPlatform.icon?.url}
								/>
							</a>
						)}
						<div className="ml-auto mb-auto flex-column sm:flex-row">
							<div className="ml-5">
								<StyledTertiaryButton
									style={{ fontSize: 16 }}
									label={"Ausloggen"}
									className="w-full sm:w-auto mb-auto p-button-warning bg-white"
									onClick={() =>
										ssoLogout({
											variables: {
												input: {},
											},
											onCompleted: () => {
												dispatch(logout());
												navigate(`/login/${brandIdParam}`);
											},
											onError: () => {
												toast.error("Das Ausloggen war nicht erfolgreich.");
											},
										})
									}
								/>
								<PrimaryButton
									label={getButtonLabel()}
									className="sm:ml-5 mb-auto"
									onClick={() => {
										window.location.href = getNavigationUrl();
									}}
								/>
							</div>
						</div>
					</div>
				}
			>
				<Suspense fallback={<Loader />}>{children}</Suspense>
			</ContentContainer>
		</BrandedContainer>
	);
};

const ContentContainer = styled(Card)`
	max-width: 1024px;
	height: 100%;
	width: 100%;
`;

export const StyledTertiaryButton = styled(TertiaryButton)`
	.p-button-label {
		font-size: 16px;
	}
`;
