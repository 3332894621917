import React, { useEffect, useState } from "react";
import MaintenanceBg from "../assets/bg-maintenance.jpg";
import styled from "styled-components";
import MaintenanceBgSmall from "../assets/bg-maintenance-small.jpg";
import MaintenanceBgLong from "../assets/bg-maintenance-long.jpg";
import MaintenanceBgMedium from "../assets/bg-maintenance-medium.jpg";

export const MaintenanceScreen = () => {
	const [backgroundImage, setBackgroundImage] = useState(selectPictureBasedOnScreenWidth());

	function selectPictureBasedOnScreenWidth() {
		if (window.innerWidth > 1024) {
			return MaintenanceBg;
		} else if (window.innerWidth <= 1024 && window.innerWidth > 820) {
			return MaintenanceBgLong;
		} else if (window.innerWidth <= 820 && window.innerWidth > 480) {
			return MaintenanceBgMedium;
		} else {
			return MaintenanceBgSmall;
		}
	}

	useEffect(() => {
		const handleResize = () => {
			setBackgroundImage(selectPictureBasedOnScreenWidth());
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<PageContainer imageurl={backgroundImage}>
			<CardContainer>
				<CardMaintenance>
					<DescriptionContainer>
						<h3 className="m-0 text-center">
							Diese Seite ist momentan aufgrund von Wartungsarbeiten geschlossen.
						</h3>
					</DescriptionContainer>
				</CardMaintenance>
			</CardContainer>
		</PageContainer>
	);
};

interface ImageProps {
	imageurl?: string | null;
}

export const PageContainer = styled.div<ImageProps>`
	width: 100%;
	height: 100%;
	background-image: url(${(p) => p.imageurl});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;
export const CardContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 40px;
`;
const CardMaintenance = styled.div`
	background-color: #545e60;
	max-width: 500px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px;
	margin: 10px;
	@media screen and (max-width: 480px) {
		padding: 15px;
	}
`;

export const DescriptionContainer = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
