import { InfectopharmRole } from "../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { InfectopharmCountry } from "../../__generated__/SignupProcessScreen_Query.graphql";

export const isRoleAndLocationEFNCompliant = (
	targetRole: InfectopharmRole = "Other",
	country: InfectopharmCountry = "Deutschland",
) => {
	return (
		targetRole === "Doctor" ||
		targetRole === "AlliedHealthProfessionals" ||
		(targetRole === "Dentist" && country === "Oesterreich")
	);
};
