import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFragment } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { SignupProcessStatus } from "../../../../__generated__/SignUpProcessDialog_ProvidePersonalDataMutation.graphql";
import { RenderConfig, ValidatedField } from "../../core/components/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../core/components/form/DefaultTextInput";
import { Dropdown } from "primereact/dropdown";
import { RoleTranslations } from "../../core/i18n/Translations";
import { PersonalAndProfessionalDataStep_BrandFragment$key } from "../../../../__generated__/PersonalAndProfessionalDataStep_BrandFragment.graphql";
import { Newsletter } from "../model/Newsletter";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { Salutation } from "../../../../__generated__/DashboardScreen_UserStatusFragment.graphql";
import { InputText } from "primereact/inputtext";
import { useTypedDispatch } from "../../../../Store";
import { setSignUpUserData } from "../../../redux/slices/SignUpProcessSlice";
import { INITIAL_STATE } from "../../../redux/slices/SignUpProcessSlice";
import { FormStateSignUp } from "../../../redux/slices/SignUpProcessSlice";
import { SignUpProcessDialog } from "../SignUpProcessDialog";
import {
	AddressValidationHelper,
	AustriaPaediaTargetRoles,
	EfnValidationHelper,
} from "../helpers/countryRelatedHelpers";
import { SpecialityAutoCompleteTextInput } from "../../core/components/form/SpecialityAutoCompleteTextInput";
import {
	getAvailableSpecialitiesList,
	OmittedInfectopharmRoles,
} from "../helpers/specialityHelper";
import { InfectopharmRole } from "../../../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { PrimaryButton } from "../../core/components/buttons/PrimaryButton";
import { StyledCheckbox } from "../../core/components/buttons/CustomedCheckbox";
import { isRoleAndLocationEFNCompliant } from "../../../helpers/isRoleAndLocationEFNCompliant";
import { InfectopharmCountry } from "../../../../__generated__/SignupProcessScreen_Query.graphql";
import { isTargetRoleWithSpeciality } from "../../../helpers/isTargetRoleWithSpeciality";

const BRAND_FRAGMENT = graphql`
	fragment PersonalAndProfessionalDataStep_BrandFragment on Brand {
		id
		availableRoles
		brandColorHex
	}
`;

export const PhoneOrFaxRegEx = /^[+][1-9]{2,3}[0-9]{3}[0-9]{4,9}$/;
export const InvalidPhoneOrFaxMessage =
	"Bitte im internationalen Format und ohne Leerzeichen angeben z.B. +49...";
export const MobileConsentText =
	"Ich stimme der Nutzung meiner angegebenen Mobilfunknummer für den Versand von Fortbildungs-, Präparate- und Unternehmensinformationen zu.";

interface OwnProps {
	brandFragmentRef: PersonalAndProfessionalDataStep_BrandFragment$key;
	signupProcessId: string;
	advance: (newStatus: SignupProcessStatus, newsletters?: ReadonlyArray<Newsletter>) => void;
}

export const showAvailableRolesBasedOnCountry = (
	availableRoles: readonly InfectopharmRole[],
	country: InfectopharmCountry,
) => {
	if (country == "Deutschland") {
		return [...availableRoles];
	} else {
		return [...availableRoles.filter((r) => r !== "AlliedHealthProfessionals")];
	}
};

export const PersonalAndProfessionalDataStep = ({
	brandFragmentRef,
	signupProcessId,
	advance,
}: OwnProps) => {
	const brand = useFragment<PersonalAndProfessionalDataStep_BrandFragment$key>(
		BRAND_FRAGMENT,
		brandFragmentRef,
	);
	const dispatch = useTypedDispatch();
	const [isVisible, setVisible] = useState(false);

	const isPaedia = brand.id === process.env.REACT_APP_PAEDIA_ID;

	const formik = useFormik<FormStateSignUp>({
		initialValues: INITIAL_STATE,
		validationSchema: Yup.object().shape({
			...AddressValidationHelper,
			salutation: Yup.string().test(
				"empty-check",
				"Das Feld Anrede wird benötigt.",
				(salutation) => salutation !== "Unknown",
			),
			firstName: Yup.string().required("Das Feld Vorname wird benötigt."),
			lastName: Yup.string().required("Das Feld Nachname wird benötigt."),
			targetRole: Yup.string()
				.when("country", {
					is: (country: string) => {
						return country == "Oesterreich" && isPaedia;
					},
					then: Yup.string().test(
						"test-values-array",
						"Beruf wird benötigt",
						(targetRole) => targetRole == "PTA" || targetRole == "Midwife",
					),
				})
				.required("Beruf wird benötigt"),
			phone: Yup.string()
				.matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage)
				.required("Telefonnummer wird benötigt"),
			mobile: Yup.string().matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage),
			userConsentToMobileMarketing: Yup.boolean(),
			fax: Yup.string().matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage),
			efnNumber: EfnValidationHelper,
			fachrichtung: Yup.string().when("targetRole", {
				is: (targetRole: InfectopharmRole) => {
					return isTargetRoleWithSpeciality(targetRole);
				},
				then: Yup.string().required("Das Feld Fachrichtung wird benötigt.").nullable(true),
			}),
		}),
		onSubmit: (values) => {
			dispatch(
				setSignUpUserData({
					salutation: values.salutation,
					title: values.title,
					firstName: values.firstName,
					lastName: values.lastName,
					targetRole: values.targetRole,
					efnNumber: values.efnNumber,
					fachrichtung: values.fachrichtung,
					company: values.company,
					street: values.street,
					postalCode: values.postalCode,
					city: values.city,
					country: values.country,
					phone: values.phone,
					mobile: values.mobile,
					userConsentToMobileMarketing: values.userConsentToMobileMarketing,
					fax: values.fax,
				}),
			);
			setVisible(true);
		},
	});

	const resetFachrichtung = () => {
		formik.setFieldValue("fachrichtung", "");
	};

	return (
		<div>
			<h1 className="mb-4">Persönliche und Berufliche Daten</h1>
			<div className="text-sm mb-4">
				<p>
					Bitte füllen Sie die nachfolgenden Felder zu Ihren persönlichen und beruflichen
					Daten aus und klicken Sie dann auf „Weiter“{" "}
				</p>
			</div>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormStateSignUp, Salutation>
					name={"salutation"}
					label={"Anrede"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									options={[
										{ label: "Herr", value: "Mr" },
										{
											label: "Frau",
											value: "Mrs",
										},
										{ label: "Divers", value: "Divers" },
									]}
									optionValue={"value"}
									optionLabel={"label"}
									className={classNames({ "p-invalid": !isValid })}
								/>
							</div>
						);
					}}
				/>
				<ValidatedField<FormStateSignUp, string>
					name={"title"}
					label={"Titel"}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									options={[
										{ label: "Kein", value: "" },
										{ label: "Dr.", value: "Dr" },
										{ label: "Prof.", value: "Prof" },
										{ label: "Prof. Dr.", value: "ProfDr" },
									]}
									optionValue={"value"}
									optionLabel={"label"}
									className={classNames({ "p-invalid": !isValid })}
								/>
							</div>
						);
					}}
				/>
				<ValidatedField<FormStateSignUp, string>
					name={"firstName"}
					label={"Vorname"}
					iconClass={"pi-user"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<InputText
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>
				<ValidatedField<FormStateSignUp, string>
					name={"lastName"}
					label={"Nachname"}
					iconClass={"pi-user"}
					required={true}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<InputText
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>
				<ValidatedField<FormStateSignUp, string>
					name={"country"}
					label={"Land"}
					iconClass={"pi-street"}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									onChange={(e) => {
										updateField(e.value);
										resetFachrichtung();
									}}
									options={[
										{ value: "Deutschland", label: "Deutschland" },
										{
											value: "Oesterreich",
											label: "Österreich",
										},
									]}
									className={classNames({
										"p-invalid": !isValid,
									})}
								/>
							</div>
						);
					}}
				/>
				<ValidatedField<FormStateSignUp, string>
					name={"targetRole"}
					label={"Ich bin ein"}
					required={true}
					formikConfig={formik}
					className="mb-3"
					component={({ fieldValue, fieldName, updateField, isValid }) => {
						return (
							<Dropdown
								name={fieldName}
								value={fieldValue}
								onChange={(e) => {
									updateField(e.value);
									resetFachrichtung();
								}}
								className={classNames({ "p-invalid": !isValid })}
								options={(isPaedia
									? formik.values.country == "Oesterreich"
										? AustriaPaediaTargetRoles
										: [...brand.availableRoles]
									: brand.availableRoles
									? showAvailableRolesBasedOnCountry(
											brand.availableRoles,
											formik.values.country || "Deutschland",
									  )
									: ["Apothecary", "Doctor", "Midwife", "PTA", "PKA"]
								)
									.map((role) => {
										return {
											value: role,
											label: RoleTranslations[role] || role,
										};
									})
									.sort((a, b) => a.label.localeCompare(b.label))
									.concat({ value: "Other", label: RoleTranslations["Other"] })}
							/>
						);
					}}
				/>

				{formik.values.targetRole === "Other" ? (
					<>
						<Message
							className="p-mb-2"
							severity="error"
							text={`Lieber Interessent, es tut uns leid, wir können für Ihren Fachkreis keine speziellen Inhalte zur Verfügung stellen. Aus diesem Grund ist eine Registrierung leider nicht möglich. Sollten Sie Fragen an uns haben, kontaktieren Sie uns bitte unter kontakt@infectopharm.com`}
						/>
					</>
				) : (
					<>
						{isRoleAndLocationEFNCompliant(
							formik.values.targetRole,
							formik.values.country,
						) && (
							<ValidatedField<FormStateSignUp, string>
								name={"efnNumber"}
								label={`${
									formik.values.country == "Oesterreich"
										? "Österreichische Ärztekammer-Nummer (ÖÄK-Arztnummer)"
										: "Einheitliche Fortbildungs-Nummer (EFN)"
								}`}
								iconClass={"pi-user"}
								required={false}
								formikConfig={formik}
								component={DefaultTextFieldComponent}
							/>
						)}

						{isTargetRoleWithSpeciality(formik.values.targetRole) && (
							<ValidatedField<FormStateSignUp, string>
								name={"fachrichtung"}
								label={"Fachrichtung"}
								// iconClass={"pi-search-plus"}
								required={isTargetRoleWithSpeciality(formik.values.targetRole)}
								formikConfig={formik}
								component={(renderConfig: RenderConfig<string>) => (
									<SpecialityAutoCompleteTextInput
										brandColor={brand.brandColorHex}
										items={getAvailableSpecialitiesList(
											formik.values.country ?? "Deutschland",
											(formik.values
												.targetRole as OmittedInfectopharmRoles) ??
												"Unknown",
										)}
										renderConfig={renderConfig}
									/>
								)}
							/>
						)}

						{formik.values.targetRole && (
							<>
								<h2 className="mt-6">Dienstliche Anschrift</h2>

								<ValidatedField<FormStateSignUp, string>
									name={"company"}
									label={"Praxis / Institution"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>

								<ValidatedField<FormStateSignUp, string>
									name={"street"}
									label={"Straße und Hausnummer"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>

								<ValidatedField<FormStateSignUp, string>
									name={"postalCode"}
									label={"PLZ"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>

								<ValidatedField<FormStateSignUp, string>
									name={"city"}
									label={"Stadt"}
									iconClass={"pi-street"}
									formikConfig={formik}
									required={true}
									preventTrimStart={true}
									component={DefaultTextFieldComponent}
								/>

								<h2 className="mt-6">Dienstliche Kontaktdaten</h2>

								<ValidatedField<FormStateSignUp, string>
									name={"phone"}
									label={"Telefonnummer"}
									iconClass={"pi-phone"}
									formikConfig={formik}
									required={true}
									component={DefaultTextFieldComponent}
								/>

								<ValidatedField<FormStateSignUp, string>
									name={"mobile"}
									label={"Mobil-Nummer"}
									iconClass={"pi-mobile"}
									formikConfig={formik}
									required={false}
									component={DefaultTextFieldComponent}
								/>

								{formik.values.mobile && formik.values.mobile?.length > 0 && (
									<ValidatedField<FormStateSignUp, boolean>
										name={"userConsentToMobileMarketing"}
										formikConfig={formik}
										component={({ fieldValue, updateField, fieldName }) => {
											return (
												<div className="p-col-12 flex">
													<StyledCheckbox
														brandColor={brand.brandColorHex}
														className="mr-2 inline-block"
														inputId={fieldName}
														onChange={(e) => {
															updateField(e.checked);
														}}
														checked={fieldValue!}
													/>
													<label
														htmlFor={fieldName}
														className="p-checkbox-label inline-block"
													>
														{MobileConsentText}
													</label>
												</div>
											);
										}}
									/>
								)}

								<ValidatedField<FormStateSignUp, string>
									name={"fax"}
									label={"Faxnummer"}
									iconClass={"pi-fax"}
									formikConfig={formik}
									component={DefaultTextFieldComponent}
								/>
							</>
						)}

						<PrimaryButton type="submit" label="Weiter" className="p-mt-2" />
					</>
				)}
			</form>
			{isVisible && (
				<SignUpProcessDialog
					isVisible={isVisible}
					setVisible={setVisible}
					brand={brand}
					signupProcessId={signupProcessId}
					advance={advance}
				/>
			)}
		</div>
	);
};
