import React, { useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../core/components/form/ValidatedField";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import { InfectopharmCountry } from "../../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { ChangePrivateAddressForm_ChangePrivateAddressMutation } from "../../../__generated__/ChangePrivateAddressForm_ChangePrivateAddressMutation.graphql";
import { Dropdown } from "primereact/dropdown";
import { DefaultTextFieldComponent } from "../core/components/form/DefaultTextInput";
import { ChangePrivateAddressForm_UserFragment$key } from "../../../__generated__/ChangePrivateAddressForm_UserFragment.graphql";
import { useDialogLogic } from "../core/components/dialog/useDialogLogic";
import { PrimaryButton } from "../core/components/buttons/PrimaryButton";

const USER_FRAGMENT = graphql`
	fragment ChangePrivateAddressForm_UserFragment on UserInformation {
		id
		roleApplicationProcess {
			privateAddress {
				street
				postalCode
				city
				country
			}
		}
	}
`;

const CHANGE_PRIVATE_ADDRESS_MUTATION = graphql`
	mutation ChangePrivateAddressForm_ChangePrivateAddressMutation(
		$input: SupplyPrivateAddressInput!
	) {
		InfectopharmAuth {
			supplyPrivateAddress(input: $input) {
				userInformation {
					id
					roleApplicationProcess {
						privateAddress {
							street
							country
							city
							postalCode
						}
					}
				}
				clientMutationId
			}
		}
	}
`;

interface FormState {
	street: string;
	postalCode: string;
	city: string;
	country: InfectopharmCountry;
}

interface OwnProps {
	userFragmentRef: ChangePrivateAddressForm_UserFragment$key;
}

export const ChangePrivateAddressForm = ({ userFragmentRef }: OwnProps) => {
	const { dialogComponent, showDialog } = useDialogLogic();
	const user = useFragment<ChangePrivateAddressForm_UserFragment$key>(
		USER_FRAGMENT,
		userFragmentRef,
	);
	const [selectedButton, setSelectedButton] = useState("");
	const [changePrivateAddress, isChangingPrivateAddress] =
		useMutation<ChangePrivateAddressForm_ChangePrivateAddressMutation>(
			CHANGE_PRIVATE_ADDRESS_MUTATION,
		);

	const formik = useFormik<FormState>({
		initialValues: {
			street: user.roleApplicationProcess?.privateAddress?.street || "",
			postalCode: user.roleApplicationProcess?.privateAddress?.postalCode || "",
			city: user.roleApplicationProcess?.privateAddress?.city || "",
			country: user.roleApplicationProcess?.privateAddress?.country || "Deutschland",
		},
		validationSchema: Yup.object().shape({
			street: Yup.string().required("Straße wird benötigt"),
			postalCode: Yup.string().when("country", (country) => {
				return Yup.string()
					.length(
						country === "Deutschland" ? 5 : 4,
						`Bitte geben Sie eine gültige PLZ ein.`,
					)
					.matches(/^\d+$/, `Bitte geben Sie eine gültige PLZ ein.`)
					.required("PLZ wird benötigt.");
			}),
			city: Yup.string().required("Stadt wird benötigt"),
		}),
		onSubmit: (values) => {
			if (selectedButton == "update") {
				changePrivateAddress({
					variables: {
						input: {
							street: values.street,
							postalCode: values.postalCode,
							city: values.city,
							country: values.country,
						},
					},
					onCompleted: () => {
						toast("Privatadresse erfolgreich geändert.", { type: "success" });
					},
				});
			} else if (selectedButton == "delete") {
				showDialog({
					title: "Meine Privatadresse löschen",
					content: "Möchten Sie Ihre Privatadresse löschen?",
					affirmativeText: "Ja, meine Privatadresse löschen.",
					negativeText: "Nein",
					dialogCallback: (result) => {
						if (result === "Accept") {
							changePrivateAddress({
								variables: {
									input: {
										street: "",
										postalCode: "",
										city: "",
										country: "Deutschland",
									},
								},
								onCompleted: () => {
									formik.resetForm();
									toast("Die Privatadresse wurde gelöscht.", { type: "success" });
								},
							});
						}
					},
				});
			}
		},
	});
	return (
		<div>
			{dialogComponent}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"street"}
					label={"Straße und Hausnummer"}
					iconClass={"pi-street"}
					formikConfig={formik}
					required={true}
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<FormState, string>
					name={"postalCode"}
					label={"PLZ"}
					iconClass={"pi-street"}
					formikConfig={formik}
					required={true}
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<FormState, string>
					name={"city"}
					label={"Stadt"}
					iconClass={"pi-street"}
					formikConfig={formik}
					required={true}
					component={DefaultTextFieldComponent}
				/>
				<ValidatedField<FormState, string>
					name={"country"}
					label={"Land"}
					iconClass={"pi-street"}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<div>
								<Dropdown
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.value)}
									options={[
										{ value: "Deutschland", label: "Deutschland" },
										{
											value: "Oesterreich",
											label: "Österreich",
										},
									]}
									className={classNames({ "p-invalid": !isValid })}
								/>
							</div>
						);
					}}
				/>
				<PrimaryButton
					onClick={() => setSelectedButton("update")}
					disabled={isChangingPrivateAddress || !formik.isValid}
					label={"Speichern"}
				/>
				<PrimaryButton
					className="mt-2"
					onClick={() => setSelectedButton("delete")}
					disabled={isChangingPrivateAddress || !formik.isValid}
					label={"Privatadresse löschen"}
				/>
			</form>
		</div>
	);
};
