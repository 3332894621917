/**
 * @generated SignedSource<<5f6ae22441ebf184121381d95ce1d9b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NeedsEmailValidationStep_BrandFragment$data = {
  readonly availableRoles: ReadonlyArray<InfectopharmRole>;
  readonly brandColorHex: string;
  readonly id: string;
  readonly " $fragmentType": "NeedsEmailValidationStep_BrandFragment";
};
export type NeedsEmailValidationStep_BrandFragment$key = {
  readonly " $data"?: NeedsEmailValidationStep_BrandFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NeedsEmailValidationStep_BrandFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NeedsEmailValidationStep_BrandFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableRoles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "brandColorHex",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

(node as any).hash = "24f06d8d8f09a33d403983f9e0ffea41";

export default node;
