/**
 * @generated SignedSource<<54e42904605fbb50c261003443569772>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
export type RoleApplicationStatus = "Finished" | "Started" | "%future added value";
export type UserStatus = "Active" | "Inactive" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RolesDisplay_UserStatusFragment$data = {
  readonly email: string;
  readonly id: string;
  readonly isLocked: boolean;
  readonly roleApplicationProcess: {
    readonly address: {
      readonly city: string;
      readonly company: string | null;
      readonly postalCode: string;
      readonly street: string;
    };
    readonly contactDetails: {
      readonly faxNumber: string | null;
      readonly mobileNumber: string | null;
      readonly phoneNumber: string;
    };
    readonly status: RoleApplicationStatus;
    readonly targetRole: InfectopharmRole;
    readonly userStatus: UserStatus;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EditRoleForm_UserInformationFragment">;
  readonly " $fragmentType": "RolesDisplay_UserStatusFragment";
};
export type RolesDisplay_UserStatusFragment$key = {
  readonly " $data"?: RolesDisplay_UserStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RolesDisplay_UserStatusFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RolesDisplay_UserStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "targetRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactDetails",
          "kind": "LinkedField",
          "name": "contactDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "faxNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobileNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditRoleForm_UserInformationFragment"
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "9d96e6418141e4ed68176fb8f9a0e03c";

export default node;
