/**
 * @generated SignedSource<<27fcaa579fe8c1fc5b76ff31ee10f99d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "%future added value";
export type SupplyPrivateAddressInput = {
  city: string;
  clientMutationId?: string | null;
  country: InfectopharmCountry;
  postalCode: string;
  street: string;
};
export type ChangePrivateAddressForm_ChangePrivateAddressMutation$variables = {
  input: SupplyPrivateAddressInput;
};
export type ChangePrivateAddressForm_ChangePrivateAddressMutation$data = {
  readonly InfectopharmAuth: {
    readonly supplyPrivateAddress: {
      readonly clientMutationId: string | null;
      readonly userInformation: {
        readonly id: string;
        readonly roleApplicationProcess: {
          readonly privateAddress: {
            readonly city: string;
            readonly country: InfectopharmCountry;
            readonly postalCode: string;
            readonly street: string;
          } | null;
        } | null;
      };
    } | null;
  };
};
export type ChangePrivateAddressForm_ChangePrivateAddressMutation = {
  response: ChangePrivateAddressForm_ChangePrivateAddressMutation$data;
  variables: ChangePrivateAddressForm_ChangePrivateAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfectopharmAuthMutations",
    "kind": "LinkedField",
    "name": "InfectopharmAuth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "SupplyPrivateAddressPayload",
        "kind": "LinkedField",
        "name": "supplyPrivateAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserInformation",
            "kind": "LinkedField",
            "name": "userInformation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleApplicationProcess",
                "kind": "LinkedField",
                "name": "roleApplicationProcess",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "privateAddress",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "street",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangePrivateAddressForm_ChangePrivateAddressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangePrivateAddressForm_ChangePrivateAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fae3bd1c11d40620e47d9f2a838a89b1",
    "id": null,
    "metadata": {},
    "name": "ChangePrivateAddressForm_ChangePrivateAddressMutation",
    "operationKind": "mutation",
    "text": "mutation ChangePrivateAddressForm_ChangePrivateAddressMutation(\n  $input: SupplyPrivateAddressInput!\n) {\n  InfectopharmAuth {\n    supplyPrivateAddress(input: $input) {\n      userInformation {\n        id\n        roleApplicationProcess {\n          privateAddress {\n            street\n            country\n            city\n            postalCode\n          }\n        }\n      }\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "070cf49f493f762efb269fcf1e16b664";

export default node;
