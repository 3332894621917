import { ValidatedField } from "../../core/components/form/ValidatedField";
import { DefaultTextFieldComponent } from "../../core/components/form/DefaultTextInput";
import React from "react";
import { FormikState } from "formik";
import { MobileConsentText } from "../../signup/steps/PersonalAndProfessionalDataStep";
import { StyledCheckbox } from "../../core/components/buttons/CustomedCheckbox";

export interface ContactDetailsFormState {
	phone: string;
	fax?: string;
	mobile?: string;
	userConsentToMobileMarketing?: boolean;
}

interface OwnProps {
	formik: FormikState<ContactDetailsFormState> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};
}

export const ContactDetailsFormPart = ({ formik }: OwnProps) => {
	return (
		<>
			<h2>Dienstliche Kontaktdaten</h2>
			<ValidatedField<ContactDetailsFormState, string>
				name={"phone"}
				label={"Telefonnummer"}
				iconClass={"pi-phone"}
				formikConfig={formik}
				required={true}
				component={DefaultTextFieldComponent}
			/>
			<ValidatedField<ContactDetailsFormState, string>
				name={"mobile"}
				label={"Mobil-Nummer"}
				iconClass={"pi-phone"}
				formikConfig={formik}
				required={false}
				component={DefaultTextFieldComponent}
			/>
			{formik.values.mobile && formik.values.mobile?.length > 0 && (
				<ValidatedField<ContactDetailsFormState, boolean>
					name={"userConsentToMobileMarketing"}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName }) => {
						return (
							<div className="p-col-12 flex">
								<StyledCheckbox
									brandColor="#4BA540"
									className="mr-2 inline-block"
									inputId={fieldName}
									onChange={(e) => {
										updateField(e.checked);
									}}
									checked={fieldValue!}
								/>
								<label
									htmlFor={fieldName}
									className="p-checkbox-label inline-block"
								>
									{MobileConsentText}
								</label>
							</div>
						);
					}}
				/>
			)}
			<ValidatedField<ContactDetailsFormState, string>
				name={"fax"}
				label={"Faxnummer"}
				iconClass={"pi-fax"}
				formikConfig={formik}
				component={DefaultTextFieldComponent}
			/>
		</>
	);
};
