import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { useLazyLoadQuery, useMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import { FORGOT_PASSWORD_PATH } from "../routes/NoLoginAuthModuleRoutes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../components/core/components/form/ValidatedField";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";
import { ForgotPasswordScreen_Query } from "../../__generated__/ForgotPasswordScreen_Query.graphql";
import { ForgotPasswordScreen_ForgotPasswordMutation } from "../../__generated__/ForgotPasswordScreen_ForgotPasswordMutation.graphql";
import { NonAuthScreenBase } from "../components/core/components/base/NonAuthScreenBase";
import { PrimaryButton } from "../components/core/components/buttons/PrimaryButton";

const QUERY = graphql`
	query ForgotPasswordScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Brand {
				name
				id
				brandColorHex
			}
			...NonAuthScreenBase_BrandFragment
		}
	}
`;

const FORGOT_PASSWORD_MUTATION = graphql`
	mutation ForgotPasswordScreen_ForgotPasswordMutation($input: ForgotPasswordInput!) {
		InfectopharmAuth {
			forgotPassword(input: $input) {
				clientMutationId
			}
		}
	}
`;

interface FormState {
	email: string;
}

export const ForgotPasswordScreen = () => {
	const router = useMatch(FORGOT_PASSWORD_PATH);

	const data = useLazyLoadQuery<ForgotPasswordScreen_Query>(QUERY, {
		id: router?.params.brandId!,
		skip: !router?.params.brandId,
	});
	const [forgotPassword, inFlight] =
		useMutation<ForgotPasswordScreen_ForgotPasswordMutation>(FORGOT_PASSWORD_MUTATION);
	const [isSuccessful, setSuccessful] = useState<boolean>(false);
	const brandId = data?.node?.id || process.env.REACT_APP_WISSENWIRKT_ID;
	const formik = useFormik<FormState>({
		initialValues: {
			email: "",
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Bitte geben Sie eine gültige E-Mail ein")
				.required("Das Feld E-Mail wird benötigt."),
		}),
		onSubmit: (values) => {
			forgotPassword({
				variables: {
					input: {
						email: values.email,
						brandId: brandId!,
					},
				},
				onCompleted: () => {
					setSuccessful(true);
				},
			});
		},
	});

	return (
		<NonAuthScreenBase brandFragmentRef={data.node}>
			<h1 className="mb-4">Password zurücksetzen</h1>
			<div className="text-sm mb-4">
				<p>
					Hier können Sie Ihr Password auf {data.node?.name || "infectopharm.com"}{" "}
					zurücksetzen.
				</p>
			</div>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, string>
					name={"email"}
					label={"E-Mail"}
					iconClass={"pi-envelope"}
					formikConfig={formik}
					component={({ fieldValue, updateField, fieldName, isValid }) => {
						return (
							<InputText
								id={fieldName}
								name={fieldName}
								value={fieldValue}
								onChange={(e) => updateField(e.target.value)}
								className={classNames({ "p-invalid": !isValid })}
							/>
						);
					}}
				/>

				{isSuccessful ? (
					<Message
						className="mb-2"
						severity="success"
						text="Wir haben Ihnen eine E-Mail zum zurücksetzen Ihres Passworts geschickt. Bitte sehen Sie in Ihrem E-Mail-Postfach nach und folgen Sie den Anweisungen in der E-Mail."
					/>
				) : (
					<PrimaryButton
						disabled={inFlight}
						type="submit"
						label="Password zurücksetzen..."
						className="p-mt-2"
					/>
				)}
			</form>
		</NonAuthScreenBase>
	);
};
