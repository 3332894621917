/**
 * @generated SignedSource<<0752ed019f8e3c0c0c58faba58e37e07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "%future added value";
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SupplyEfnForm_UserFragment$data = {
  readonly efnNumber: string | null;
  readonly id: string;
  readonly isLocked: boolean;
  readonly roleApplicationProcess: {
    readonly address: {
      readonly country: InfectopharmCountry;
    };
    readonly targetRole: InfectopharmRole;
  } | null;
  readonly " $fragmentType": "SupplyEfnForm_UserFragment";
};
export type SupplyEfnForm_UserFragment$key = {
  readonly " $data"?: SupplyEfnForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SupplyEfnForm_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SupplyEfnForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "efnNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "targetRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "1277bf89f1641ab976bfeaf1438277c9";

export default node;
