/**
 * @generated SignedSource<<6bd48ccc8eac7bfe32c664a6cb8250bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "%future added value";
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
export type RegistrationDisplayLocation = "Profile" | "Registration" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Newsletters_UserStatusFragment$data = {
  readonly roleApplicationProcess: {
    readonly address: {
      readonly country: InfectopharmCountry;
    };
    readonly targetRole: InfectopharmRole;
  } | null;
  readonly signedUpOverBrand: {
    readonly newsletters: ReadonlyArray<{
      readonly countries: ReadonlyArray<InfectopharmCountry>;
      readonly description: string;
      readonly id: string;
      readonly limitToRoles: ReadonlyArray<InfectopharmRole>;
      readonly name: string;
      readonly registrationDisplayLocations: ReadonlyArray<RegistrationDisplayLocation>;
    }>;
  } | null;
  readonly signedUpToNewsletters: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentType": "Newsletters_UserStatusFragment";
};
export type Newsletters_UserStatusFragment$key = {
  readonly " $data"?: Newsletters_UserStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Newsletters_UserStatusFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Newsletters_UserStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "targetRole",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "signedUpOverBrand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Newsletter",
          "kind": "LinkedField",
          "name": "newsletters",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registrationDisplayLocations",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "countries",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "limitToRoles",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Newsletter",
      "kind": "LinkedField",
      "name": "signedUpToNewsletters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};
})();

(node as any).hash = "8868650d7753d15d66e87c7f4dc09db8";

export default node;
