import React from "react";
import { Dialog } from "primereact/dialog";
import { FormStateSignUp } from "../../redux/slices/SignUpProcessSlice";
import { RenderConfig, ValidatedField } from "../core/components/form/ValidatedField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Salutation } from "../../../__generated__/DashboardScreen_UserStatusFragment.graphql";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { RoleTranslations } from "../core/i18n/Translations";
import { Message } from "primereact/message";
import { DefaultTextFieldComponent } from "../core/components/form/DefaultTextInput";
import { SignUpProcessDialog_ProvidePersonalDataMutation } from "../../../__generated__/SignUpProcessDialog_ProvidePersonalDataMutation.graphql";
import { SignupProcessStatus } from "../../../__generated__/SignUpProcessDialog_ProvidePersonalDataMutation.graphql";
import { Newsletter } from "./model/Newsletter";
import graphql from "babel-plugin-relay/macro";
import styled from "styled-components";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import {
	AddressValidationHelper,
	AustriaPaediaTargetRoles,
	EfnValidationHelper,
} from "./helpers/countryRelatedHelpers";
import { InfectopharmRole } from "../../../__generated__/SupplyEfnForm_UserFragment.graphql";
import { SpecialityAutoCompleteTextInput } from "../core/components/form/SpecialityAutoCompleteTextInput";
import { getAvailableSpecialitiesList, OmittedInfectopharmRoles } from "./helpers/specialityHelper";
import {
	InvalidPhoneOrFaxMessage,
	MobileConsentText,
	PhoneOrFaxRegEx,
	showAvailableRolesBasedOnCountry,
} from "./steps/PersonalAndProfessionalDataStep";
import { PrimaryButton } from "../core/components/buttons/PrimaryButton";
import { SecondaryButton } from "../core/components/buttons/SecondaryButton";
import { StyledCheckbox } from "../core/components/buttons/CustomedCheckbox";
import { isRoleAndLocationEFNCompliant } from "../../helpers/isRoleAndLocationEFNCompliant";
import { isTargetRoleWithSpeciality } from "../../helpers/isTargetRoleWithSpeciality";

const MUTATION = graphql`
	mutation SignUpProcessDialog_ProvidePersonalDataMutation(
		$input: ProvidePersonalAndProfessionalDataInput!
	) {
		Signup {
			providePersonalAndProfessionalData(input: $input) {
				signupProcess {
					id
					status

					newsletters {
						id
						name
						description
						registrationDisplayLocations
						countries
					}
				}
			}
		}
	}
`;

interface OwnProps {
	brand: any;
	isVisible: boolean;
	setVisible: (boolean: boolean) => void;
	signupProcessId: string;
	advance: (newStatus: SignupProcessStatus, newsletters?: ReadonlyArray<Newsletter>) => void;
}

export function SignUpProcessDialog({
	isVisible,
	setVisible,
	brand,
	signupProcessId,
	advance,
}: OwnProps) {
	const data = localStorage.getItem("signUp-process");
	const signupData = JSON.parse(data!);

	const isPaedia = brand.id === process.env.REACT_APP_PAEDIA_ID;

	const [testAccount, isRunning] =
		useMutation<SignUpProcessDialog_ProvidePersonalDataMutation>(MUTATION);

	const formik = useFormik<FormStateSignUp>({
		initialValues: signupData,
		validationSchema: Yup.object().shape({
			...AddressValidationHelper,
			salutation: Yup.string().test(
				"empty-check",
				"Das Feld Anrede wird benötigt.",
				(salutation) => salutation !== "Unknown",
			),
			lastName: Yup.string().required("Das Feld Nachname wird benötigt."),
			firstName: Yup.string().required("Das Feld Vorname wird benötigt."),
			targetRole: Yup.string()
				.when("country", {
					is: (country: string) => {
						return country == "Oesterreich" && isPaedia;
					},
					then: Yup.string().test(
						"test-values-array",
						"Beruf wird benötigt",
						(targetRole) => targetRole == "PTA" || targetRole == "Midwife",
					),
				})
				.required("Beruf wird benötigt"),
			phone: Yup.string()
				.matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage)
				.required("Telefonnummer wird benötigt"),
			mobile: Yup.string().matches(PhoneOrFaxRegEx, InvalidPhoneOrFaxMessage),
			efnNumber: EfnValidationHelper,
			fachrichtung: Yup.string().when("targetRole", {
				is: (targetRole: InfectopharmRole) => {
					return isTargetRoleWithSpeciality(targetRole);
				},
				then: Yup.string().required("Das Feld Fachrichtung wird benötigt.").nullable(true),
			}),
		}),
		onSubmit: (values) => {
			testAccount({
				variables: {
					input: {
						id: signupProcessId,
						salutation: values.salutation,
						title: values.title,
						firstName: values.firstName,
						lastName: values.lastName,
						address: {
							company: values.company,
							country: values.country!,
							city: values.city!,
							street: values.street!,
							postalCode: values.postalCode!,
						},
						contactDetails: {
							faxNumber: values.fax,
							phoneNumber: values.phone,
							mobileNumber: values.mobile,
						},
						userConsentsMobileMarketingText: values.userConsentToMobileMarketing
							? MobileConsentText
							: null,
						efnNumber: isRoleAndLocationEFNCompliant(
							formik.values.targetRole,
							formik.values.country,
						)
							? values.efnNumber || undefined
							: undefined,
						fachrichtung: isTargetRoleWithSpeciality(values.targetRole)
							? values.fachrichtung
							: undefined,
						targetRole: values.targetRole!,
						brandId: brand.id,
					},
				},
				onCompleted: (result) => {
					advance(
						result.Signup.providePersonalAndProfessionalData?.signupProcess.status!,
						result.Signup.providePersonalAndProfessionalData?.signupProcess
							.newsletters || [],
					);
				},
				onError: () => {
					toast.error(
						"Die Anmeldung war nicht erfolgreich. Bitte überprüfen Sie Ihre Daten.",
					);
				},
			});

			setVisible(true);
		},
	});

	const resetFachrichtung = () => {
		formik.setFieldValue("fachrichtung", "");
	};

	return (
		<Dialog
			className="w-12 md:w-9 lg:w-6"
			visible={isVisible}
			closeOnEscape={false}
			closable={false}
			onHide={() => {
				setVisible(false);
			}}
			showHeader={false}
		>
			<div>
				<FormContainer>
					<ValidatedFieldRow onSubmit={formik.handleSubmit} className="p-fluid">
						<h3 className="mb-6 mt-6 ">
							Zusammenfassung Ihre persönliche und berufliche Daten
						</h3>
						<ValidatedField<FormStateSignUp, Salutation>
							name={"salutation"}
							label={"Anrede"}
							className="flex align-items-center"
							required={true}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName, isValid }) => {
								return (
									<div>
										<Dropdown
											name={fieldName}
											value={fieldValue || signupData}
											onChange={(e) => updateField(e.target.value)}
											options={[
												{ label: "Herr", value: "Mr" },
												{
													label: "Frau",
													value: "Mrs",
												},
												{ label: "Divers", value: "Divers" },
											]}
											optionValue={"value"}
											optionLabel={"label"}
											className={classNames({ "p-invalid": !isValid })}
										/>
									</div>
								);
							}}
						/>
						<ValidatedField<FormStateSignUp, string>
							name={"title"}
							label={"Titel"}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName, isValid }) => {
								return (
									<div>
										<Dropdown
											name={fieldName}
											value={fieldValue}
											onChange={(e) => updateField(e.target.value)}
											options={[
												{ label: "Kein", value: "" },
												{ label: "Dr.", value: "Dr" },
												{ label: "Prof.", value: "Prof" },
												{ label: "Prof. Dr.", value: "ProfDr" },
											]}
											optionValue={"value"}
											optionLabel={"label"}
											className={classNames({ "p-invalid": !isValid })}
										/>
									</div>
								);
							}}
						/>
						<ValidatedField<FormStateSignUp, string>
							name={"firstName"}
							label={"Vorname"}
							iconClass={"pi-user"}
							required={true}
							disabled={true}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName, isValid }) => {
								return (
									<InputText
										id={fieldName}
										name={fieldName}
										value={fieldValue}
										onChange={(e) => updateField(e.target.value)}
										className={classNames({ "p-invalid": !isValid })}
									/>
								);
							}}
						/>
						<ValidatedField<FormStateSignUp, string>
							name={"lastName"}
							label={"Nachname"}
							iconClass={"pi-user"}
							required={true}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName, isValid }) => {
								return (
									<InputText
										id={fieldName}
										name={fieldName}
										value={fieldValue}
										onChange={(e) => updateField(e.target.value)}
										className={classNames({ "p-invalid": !isValid })}
									/>
								);
							}}
						/>
						<ValidatedField<FormStateSignUp, string>
							name={"country"}
							label={"Land"}
							iconClass={"pi-street"}
							formikConfig={formik}
							component={({ fieldValue, updateField, fieldName, isValid }) => {
								return (
									<div>
										<Dropdown
											name={fieldName}
											value={fieldValue}
											onChange={(e) => {
												updateField(e.value);
												resetFachrichtung();
											}}
											options={[
												{
													value: "Deutschland",
													label: "Deutschland",
												},
												{
													value: "Oesterreich",
													label: "Österreich",
												},
											]}
											className={classNames({
												"p-invalid": !isValid,
											})}
										/>
									</div>
								);
							}}
						/>
						<ValidatedField<FormStateSignUp, string>
							name={"targetRole"}
							label={"Ich bin ein"}
							required={true}
							className="mb-3"
							formikConfig={formik}
							component={({ fieldValue, fieldName, updateField, isValid }) => {
								return (
									<Dropdown
										name={fieldName}
										value={fieldValue}
										onChange={(e) => {
											updateField(e.value);
											resetFachrichtung();
										}}
										className={classNames({ "p-invalid": !isValid })}
										options={(isPaedia
											? formik.values.country == "Oesterreich"
												? AustriaPaediaTargetRoles
												: [...brand.availableRoles]
											: brand.availableRoles
											? showAvailableRolesBasedOnCountry(
													brand.availableRoles!,
													formik.values.country || "Deutschland",
											  )
											: ["Apothecary", "Doctor", "Midwife", "PTA", "PKA"]
										)
											.map((role) => {
												return {
													value: role,
													label: RoleTranslations[role] || role,
												};
											})
											.sort((a, b) => a.label.localeCompare(b.label))
											.concat({
												value: "Other",
												label: RoleTranslations["Other"],
											})}
									/>
								);
							}}
						/>

						{formik.values.targetRole === "Other" ? (
							<>
								<Message
									className="p-mb-2"
									severity="error"
									text={`Lieber Interessent, es tut uns leid, wir können für Ihren Fachkreis keine speziellen Inhalte zur Verfügung stellen. Aus diesem Grund ist eine Registrierung leider nicht möglich. Sollten Sie Fragen an uns haben, kontaktieren Sie uns bitte unter kontakt@infectopharm.com`}
								/>
							</>
						) : (
							<>
								{isRoleAndLocationEFNCompliant(
									formik.values.targetRole,
									formik.values.country,
								) && (
									<ValidatedField<FormStateSignUp, string>
										name={"efnNumber"}
										label={`${
											formik.values.country == "Oesterreich"
												? "Österreichische Ärztekammer-Nummer (ÖÄK-Arztnummer)"
												: "Einheitliche Fortbildungs-Nummer (EFN)"
										}`}
										iconClass={"pi-user"}
										formikConfig={formik}
										required={false}
										component={DefaultTextFieldComponent}
									/>
								)}

								{isTargetRoleWithSpeciality(formik.values.targetRole) && (
									<ValidatedField<FormStateSignUp, string>
										name={"fachrichtung"}
										label={"Fachrichtung"}
										required={isTargetRoleWithSpeciality(
											formik.values.targetRole,
										)}
										formikConfig={formik}
										component={(renderConfig: RenderConfig<string>) => (
											<SpecialityAutoCompleteTextInput
												brandColor={brand.brandColorHex}
												items={getAvailableSpecialitiesList(
													formik.values.country ?? "Deutschland",
													(formik.values
														.targetRole as OmittedInfectopharmRoles) ??
														"Unknown",
												)}
												renderConfig={renderConfig}
											/>
										)}
									/>
								)}

								{formik.values.targetRole && (
									<>
										<h4 className="mt-6">Dienstliche Anschrift</h4>

										<ValidatedField<FormStateSignUp, string>
											name={"company"}
											label={"Praxis / Institution"}
											iconClass={"pi-street"}
											formikConfig={formik}
											required={true}
											preventTrimStart={true}
											component={DefaultTextFieldComponent}
										/>

										<ValidatedField<FormStateSignUp, string>
											name={"street"}
											label={"Straße und Hausnummer"}
											iconClass={"pi-street"}
											formikConfig={formik}
											required={true}
											preventTrimStart={true}
											component={DefaultTextFieldComponent}
										/>

										<ValidatedField<FormStateSignUp, string>
											name={"postalCode"}
											label={"PLZ"}
											iconClass={"pi-street"}
											formikConfig={formik}
											required={true}
											preventTrimStart={true}
											component={DefaultTextFieldComponent}
										/>

										<ValidatedField<FormStateSignUp, string>
											name={"city"}
											label={"Stadt"}
											iconClass={"pi-street"}
											formikConfig={formik}
											required={true}
											preventTrimStart={true}
											component={DefaultTextFieldComponent}
										/>

										<h4 className="mt-6">Dienstliche Kontaktdaten</h4>

										<ValidatedField<FormStateSignUp, string>
											name={"phone"}
											label={"Telefonnummer"}
											iconClass={"pi-phone"}
											formikConfig={formik}
											required={true}
											component={DefaultTextFieldComponent}
										/>

										<ValidatedField<FormStateSignUp, string>
											name={"mobile"}
											label={"Mobil-Nummer"}
											iconClass={"pi-mobile"}
											formikConfig={formik}
											required={true}
											component={DefaultTextFieldComponent}
										/>

										{formik.values.mobile && formik.values.mobile?.length > 0 && (
											<ValidatedField<FormStateSignUp, boolean>
												name={"userConsentToMobileMarketing"}
												formikConfig={formik}
												component={({
													fieldValue,
													updateField,
													fieldName,
												}) => {
													return (
														<div className="p-col-12">
															<StyledCheckbox
																brandColor={brand.brandColorHex}
																className="mr-2"
																inputId={fieldName}
																onChange={(e) => {
																	updateField(e.checked);
																}}
																checked={fieldValue!}
															/>
															<label
																htmlFor={fieldName}
																className="p-checkbox-label w-full"
															>
																{MobileConsentText}
															</label>
														</div>
													);
												}}
											/>
										)}

										<ValidatedField<FormStateSignUp, string>
											name={"fax"}
											label={"Faxnummer"}
											iconClass={"pi-fax"}
											formikConfig={formik}
											component={DefaultTextFieldComponent}
										/>
									</>
								)}

								<SubmitButton
									brandColor={brand.brandColorHex}
									disabled={isRunning}
									type="submit"
									label="Speichern & Weiter"
									className="p-mt-2 mt-4 text-xs"
								/>
							</>
						)}
						<SecondaryButton
							className="mt-2 mb-1 text-xs "
							label="Schließen"
							type="button"
							onClick={() => {
								setVisible(false);
							}}
						/>
					</ValidatedFieldRow>
				</FormContainer>
			</div>
		</Dialog>
	);
}

const ValidatedFieldRow = styled.form`
	label {
		margin-bottom: 0px;
		margin-right: 20px;
		text-align: left;
		width: 130px;
	}
	.p-inputtext {
		padding: 5px;
		text-align: left !important;
	}
	.pi {
		font-size: 0.5rem;
		padding: 2px;
	}
	.field {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.p-input-icon-right {
		margin-right: 5px;
	}
	.field > div,
	.field > span {
		width: 100%;
	}
	width: 90%;
	@media (max-width: 450px) {
		width: 90%;
	}
	.p-error {
		margin-left: 5px;
	}
	.p-button.p-button-icon-only {
		padding: 5px;
	}
`;
const FormContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
`;

export interface BrandColorProps {
	brandColor?: string;
	colorHover?: string;
	colorPress?: string;
}
const SubmitButton = styled(PrimaryButton)<BrandColorProps>`
	background-color: ${(p) => p.brandColor};
`;
