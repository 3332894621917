import React, { useEffect } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { Loader } from "../components/core/components/Loader";
import { LogoutScreen_LogoutMutation } from "../../__generated__/LogoutScreen_LogoutMutation.graphql";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../redux/slices/AuthSlice";
import { useDispatch } from "react-redux";
import { redirectAfterLogout } from "../components/auth/utils/redirect-after-logout";

const LOGOUT_MUTATION = graphql`
	mutation LogoutScreen_LogoutMutation($input: LogoutInput!) {
		InfectopharmAuth {
			logout(input: $input) {
				clientMutationId
			}
		}
	}
`;

export const LOGOUT_PATH = "/logout/:brandId/:redirectBase64?";

export const LogoutScreen = () => {
	const [ssoLogout] = useMutation<LogoutScreen_LogoutMutation>(LOGOUT_MUTATION);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useParams<{ brandId: string; redirectBase64?: string }>();

	useEffect(() => {
		ssoLogout({
			variables: {
				input: {},
			},
			onCompleted: () => {
				dispatch(logout());

				if (params.redirectBase64) {
					redirectAfterLogout(params.redirectBase64);
				} else {
					navigate(`/login/${params.brandId}`, {
						replace: true,
						relative: "path",
					});
				}
			},
			onError: () => {
				// toast.error("Login was unsuccessful. Please check your email and password.");
			},
		});
	}, []);

	return <Loader />;
};
