/**
 * @generated SignedSource<<b2283212d547790ad5eb93dcf73e36f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmCountry = "Deutschland" | "Oesterreich" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditRoleForm_UserInformationFragment$data = {
  readonly id: string;
  readonly roleApplicationProcess: {
    readonly address: {
      readonly city: string;
      readonly company: string | null;
      readonly country: InfectopharmCountry;
      readonly postalCode: string;
      readonly street: string;
    };
    readonly contactDetails: {
      readonly faxNumber: string | null;
      readonly mobileNumber: string | null;
      readonly phoneNumber: string;
    };
  } | null;
  readonly userConsentsMobileMarketing: boolean;
  readonly " $fragmentType": "EditRoleForm_UserInformationFragment";
};
export type EditRoleForm_UserInformationFragment$key = {
  readonly " $data"?: EditRoleForm_UserInformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditRoleForm_UserInformationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRoleForm_UserInformationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleApplicationProcess",
      "kind": "LinkedField",
      "name": "roleApplicationProcess",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "company",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactDetails",
          "kind": "LinkedField",
          "name": "contactDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mobileNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "faxNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userConsentsMobileMarketing",
      "storageKey": null
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "5035297390af94d51372b904a7faf8cd";

export default node;
