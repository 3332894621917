/**
 * @generated SignedSource<<c442d18835510486efdc7591ab5d7433>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InfectopharmRole = "AlliedHealthProfessionals" | "Apothecary" | "Dentist" | "Doccheck" | "Doctor" | "MTA" | "Midwife" | "Other" | "PKA" | "PTA" | "PharmaceuticalEngineer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RoleApplicationRegistrationForm_UserInformationFragment$data = {
  readonly signedUpOverBrand: {
    readonly availableRoles: ReadonlyArray<InfectopharmRole>;
    readonly brandColorHex: string;
    readonly icon: {
      readonly url: string | null;
    } | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NewsletterFormPart_BrandFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"NewsletterFormPart_UserInformationFragment">;
  readonly " $fragmentType": "RoleApplicationRegistrationForm_UserInformationFragment";
};
export type RoleApplicationRegistrationForm_UserInformationFragment$key = {
  readonly " $data"?: RoleApplicationRegistrationForm_UserInformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RoleApplicationRegistrationForm_UserInformationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleApplicationRegistrationForm_UserInformationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "signedUpOverBrand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "icon",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "brandColorHex",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewsletterFormPart_BrandFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewsletterFormPart_UserInformationFragment"
    }
  ],
  "type": "UserInformation",
  "abstractKey": null
};

(node as any).hash = "154665470b220098f72f3d332d8e9229";

export default node;
